input[type='radio'] {
  @apply border-neutral-40;
  -webkit-appearance: none;
  border-radius: 100%;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}

input[type='radio']:checked:before {
  content: url('../../icons/generic/small-tick.svg');
}

input[type='radio']:checked {
  border: none;
}
