@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply text-neutral-100 font-DMSans;
  font-size: 1rem;
  line-height: 1.2rem;
  -webkit-font-smoothing: antialiased;
}

h1 {
  @apply tracking-[-0.03rem];
  font-size: 3rem;
  line-height: 3.3rem;
  -webkit-font-smoothing: antialiased;
}

h2 {
  @apply tracking-[-0.02rem];
  font-size: 2rem;
  line-height: 2.2rem;
  -webkit-font-smoothing: antialiased;
}

h3 {
  @apply tracking-[-0.015rem];
  font-size: 1.5rem;
  line-height: 1.65rem;
  -webkit-font-smoothing: antialiased;
}

h4 {
  @apply tracking-[-0.0125rem];
  font-size: 1.25rem;
  line-height: 1.5rem;
  -webkit-font-smoothing: antialiased;
}

h5 {
  @apply tracking-[-0.01rem];
  font-size: 1.125rem;
  line-height: 1.35rem;
  -webkit-font-smoothing: antialiased;
}

.text-base {
  @apply tracking-[-0.01rem];
  font-size: 1rem;
  line-height: 1.2rem;
  -webkit-font-smoothing: antialiased;
}

.text-sm {
  @apply tracking-[-0.00875rem];
  font-size: 0.875rem;
  line-height: 1.05rem;
  -webkit-font-smoothing: antialiased;
}

.text-xs {
  @apply tracking-[-0.0075rem];
  font-size: 0.75rem;
  line-height: 0.9rem;
  -webkit-font-smoothing: antialiased;
}

small {
  @apply text-xs;
}

.font-mono {
  font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
    monospace;
}

.button {
  @apply bg-primary-100 hover:brightness-90 text-white px-7 py-2.5 rounded-full border-2 border-primary-100 ml-2;
}

.button:disabled {
  @apply bg-neutral-40 border-neutral-40;
}

.secondary {
  @apply bg-white hover:text-neutral-100 text-neutral-100;
}

.secondary:disabled {
  @apply bg-white text-neutral-20;
}

.tracking-tighter {
  letter-spacing: -1%;
}

input {
  @apply rounded border border-neutral-20 my-px focus:my-0 h-[3.25rem];
  line-height: 1.375rem;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
