input[type='checkbox'] {
  -webkit-appearance: none;
}

input[type='checkbox']:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
}

input[type='checkbox']:checked {
  border: none;
}
