:root {
  --toastify-color-success: #28c585;
  --toastify-color-warning: #ff9d66;
  --toastify-color-error: #ff6767;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-text-color-light: #051a16;
  --toastify-font-family: 'DM Sans', sans-serif;
}
